var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item" }, [
    _c(
      "div",
      {
        staticClass: "titel",
        on: {
          click: function($event) {
            return _vm.toggle()
          }
        }
      },
      [
        _vm._t("title"),
        _vm._v(" "),
        _c("i", {
          ref: "akkordeonIcon",
          staticClass: "akkordeon-icon fal fa-angle-down",
          attrs: { "aria-hidden": "true" }
        })
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { ref: "akkordeonContainer", staticClass: "text" },
      [_vm._t("content")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }