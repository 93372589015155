




































import Vue from 'vue';
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default Vue.extend({
  name: 'lightbox',
  props: {},
  components: {
    VueGallerySlideshow
  },
  data() {
    return {
      images: [
        { url: 'https://placekitten.com/801/800', text:'my alt text'},
        { url: 'https://placekitten.com/802/800', text:'my alt text3'},
        { url: 'https://placekitten.com/803/800', text:'my alt text 2'},
      ],
      index: 0,
      open: false
    };
  },
  methods: {
    nextImage() {
      this.index++;
      if (this.index >= this.images.length) {
        this.index = 0;
      }
    },
    previousImage() {
      this.index--;
      if (this.index < 0) {
        this.index = this.images.length - 1;
      }
    }
  },
});
